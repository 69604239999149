$white-shade-1: #fffefc;
$white-shade-2: #fffefc66;
$white-shade-3: #fffefc4d;
$white-shade-4: #fffefc33;
$white-shade-5: #fffefc1a;
$white-shade-6: #fffefc0d;
$white-shade-7: #fffefc80;

$dark-shade-1: #1c1c1c;
$dark-shade-2: #1c1c1c66;
$dark-shade-3: #1c1c1c4d;
$dark-shade-4: #1c1c1c33;
$dark-shade-5: #1c1c1c1a;
$dark-shade-6: #1c1c1c0d;
