.userWarningContainer {
  // border: 5px solid green;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 6px;

    img {
      height: 32px;
    }

    span {
      font-size: 25px;
      line-height: 25px;
      font-weight: 700;
      color: #fff;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    // margin: 10px 0;
    margin-bottom: 5px;

    * {
      width: max-content;
    }

    .big {
      font-size: 40px;
      line-height: 40px;
      font-weight: 700;
      color: #fff;
      // text-shadow: 5px 5px 20px rgba(255, 255, 255, 0.5);
    }

    .smol {
      font-size: 14px;
      line-height: 14px;
      color: #ffffff80;
      // margin-right: 20px;
    }
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 13px;
  }
}
