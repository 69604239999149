.layoutContainer {
  // border: 10px solid blue;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .backgroundContainer {
    height: 1500px;
    width: 1500px;
    max-width: 250vw;
    max-height: 250vw;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: -1;

    // @media (max-width: 768.5px) {
    //   top: 25%;
    // }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      transform-origin: left;
    }
  }

  .scrollContainer {
    width: 100%;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    // border: 15px solid pink;
    position: relative;
    z-index: 3;

    .pageContent {
      // border: 10px solid green !important;
      max-width: 1440px;
      // height: max-content;
      height: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 3;
      transition: 0.25s ease all;

      @media (max-width: 1500px) {
        padding: 0 30px;
      }
    }
  }
}
