// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   font-weight: 300;
//   font-style: normal;
//   letter-spacing: 0.03em;
//   font-feature-settings: 'pnum' on, 'lnum' on;
//   color: #fffefc;
// }

* {
  margin: 0;
  padding: 0;
  font-weight: 300;
  box-sizing: border-box;
  font-style: normal;
  // letter-spacing: 0.03em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

body {
  font-family: 'Raleway', sans-serif;
  background-color: #000;
  color: #fff;
}

html,
body,
#root {
  height: 100% !important;
}

// #root {
//   border: 10px solid orangered;
// }

#root > div:first-child {
  // border: 10px solid teal;
  height: 100%;
}

a {
  text-decoration: none;
}

h1,
h2,
h3 {
  text-align: center;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 400;
}

h2 {
  font-size: 25px;
  line-height: 38px;
  font-weight: 600;
}

h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

strong {
  font-weight: 700;
}

// https://stackoverflow.com/questions/2460100/remove-the-complete-styling-of-an-html-button-submit
button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button {
  padding: 10px 15px;
  border-radius: 30px;
  cursor: pointer;
  background: transparent;
  transition: 0.25s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
  color: #fff;
  border: 1px solid #fff;
  min-width: 150px;
  // background-color: black;

  svg {
    max-width: 19px;
    max-height: 19px;
  }

  g {
    stroke: $white-shade-1;
    transition: 0.25s ease all;
  }

  &:hover {
    background-color: $dark-shade-1;
    color: #fff;
    border: 1px solid transparent;
    transform: scale(1.05);

    g {
      stroke: $white-shade-2;
    }
  }

  &:active {
    transform: scale(0.92);
  }

  // @media (max-width: 768.5px) {
  //   padding: 14px;
  //   border-radius: 30px;
  //   min-width: 100px;
  //   font-size: 14px;
  //   line-height: 14px;

  //   svg {
  //     max-width: 19px;
  //     max-height: 19px;
  //   }
  // }
}

::-moz-selection {
  color: black;
  background: $white-shade-4;
}

::selection {
  color: black;
  background: $white-shade-4;
}

.greenGradient {
  background: linear-gradient(90deg, #3dfec4 0%, #bbf32e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600 !important;
}

.blueGradient {
  background: linear-gradient(90deg, #2ff5ca 0%, #6a44d7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

#w3a-container {
  z-index: 999999999999999999999999999999999999999999999999 !important;
  position: absolute !important;
}
