.synergyContainer {
  // border: 1px solid red;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // border: 1px solid red;

  @media (max-width: 768.5px) {
    max-width: 85vw;
  }

  .mx-auto {
    margin: 15px auto;
  }

  .userNotFound {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    * {
      width: max-content;
      align-self: flex-start !important;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    .title {
      font-weight: 700;
      font-size: 25px;
      line-height: 38px;
      flex-wrap: wrap;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }

    .t3 {
      margin: 25px 0;
    }

    .smolText {
      font-size: 16px;
      line-height: 18.5px;
      font-weight: 400;
      max-width: 600px;

      @media (max-width: 768.5px) {
        max-width: 85vw;
      }
    }

    .inputContainer {
      display: flex;
      gap: 30px;

      @media (max-width: 768.5px) {
        flex-direction: column;
        // border: 1px solid red;
      }

      input {
        &,
        &:active,
        &:focus,
        &:hover {
          border: 1px solid rgba(255, 255, 255, 0.1);
          outline: none;
        }
      }

      input {
        background-color: transparent;
        width: 260px;
        height: 50px;
        border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.12);
        color: #fff;
        padding: 0 15px;
        transition: 0.25s ease all;

        &::placeholder {
          color: #ffffff80;
        }
      }

      .inputErr {
        border: 1px solid #cc0000 !important;

        &::placeholder {
          color: white !important;
        }
      }

      .activeInput {
        border: 1px solid #fff !important;
      }
    }

    .line1 {
      width: 100%;
      height: 1px;
      background-color: #fff;
      // position: absolute;
      // transform: translateX(-50%);
      // bottom: 0;
      // left: 50%;
      border-radius: 13px;
      overflow: hidden;
      margin: 25px 0;

      @media (max-width: 768.5px) {
        width: 260px;
        transform: translateX(0);
        left: 0;
      }
    }

    .resultsContainer {
      width: 100%;
      // border: 1px solid #cc0000; //xdxdxd

      @media (max-width: 768.5px) {
        max-width: 85vw;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
      }

      .checkboxContainer {
        margin: 25px 0;
        width: max-content;
        height: 40px;
        // border: 1px solid red;
        display: flex;
        gap: 25px;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 16px;
          line-height: 16px;
          color: #fff;
        }

        .checkBox {
          width: 88px;
          height: 100%;
          border: 1px solid transparent;
          border-radius: 30px;
          background: linear-gradient(90deg, #3dfec4 0%, #bbf32e 100%);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0px 5px;
          cursor: pointer;
          transition: 0.25s ease all;
          position: relative;

          .dot {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: 5px;
            transition: 0.25s ease all;
          }
        }

        .deactivated {
          justify-content: flex-start !important;
          background: linear-gradient(90deg, #00000020 0%, #00000020 100%);
          border: 1px solid rgba(255, 255, 255, 0.5) !important;

          .dot {
            right: 50px !important;
          }
        }
      }

      .results {
        max-width: 775px;
        // max-height: 500px;
        overflow-y: auto;
        position: relative;
        border-radius: 15px;
        border: 1px solid rgba(255, 255, 255, 0.1);

        .topbar {
          // width: 100%;
          height: 40px;
          // border: 5px solid red;
          // background-color: red !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          position: sticky;
          backdrop-filter: blur(200px);
          background: rgba(0, 0, 0, 0.2);
          z-index: 10;
          top: 0;
          left: 0%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(200px); //mobile-friendly

          @media (max-width: 768.5px) {
            font-size: 14px;
            line-height: 14px;
          }

          .c1,
          .c2,
          .c3,
          .c4 {
            display: flex;
            justify-content: center;
            align-items: center;
            // border: 1px solid red;
          }

          .c1 {
            min-width: 40px;
            // min-height: 40px;
            // border: 1px solid red;

            // @media (max-width: 768.5px) {
            //   min-width: 0;
            //   min-height: 0;
            // }
          }

          .c2 {
            min-width: 150px;
            // min-height: 50px;
            // border: 1px solid red;
          }

          .c3 {
            min-width: 100px;
            // min-height: 50px;
            // border: 1px solid red;
          }

          .c4 {
            flex: 1;
            // min-height: 50px;
            // border: 1px solid red;
          }
        }

        .result {
          max-height: 50px;
          overflow: hidden;
          transition: 0.25s ease all;
          position: relative;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);

          @media (max-width: 768.5px) {
            font-size: 14px;
            line-height: 14px;
          }

          &:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }

          .top {
            width: 100%;
            height: 50px;
            border-bottom: 1px solid transparent;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: 0.25s ease all;
            cursor: pointer;
            position: relative;
            z-index: 1;

            .c1,
            .c2,
            .c3,
            .c4 {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .c1 {
              min-width: 40px;
              min-height: 40px;
              // border: 1px solid red;

              svg {
                cursor: pointer;
              }
            }

            .c2 {
              min-width: 150px;
              min-height: 50px;
              // border: 1px solid red;
            }

            .c3 {
              min-width: 100px;
              min-height: 50px;
              // border: 1px solid red;
            }

            .c4 {
              flex: 1;
              min-height: 50px;
              padding-right: 15px;
              // border: 1px solid red;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;

              svg {
                transition: 0.25s ease all;
                justify-self: flex-end;
                margin-left: auto;
              }

              .rotated {
                transform: rotate(180deg);
              }
            }
          }

          .bottom {
            height: 250px;
            min-height: 250px;
            max-height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 25px;
            gap: 10px;
            position: relative;
            z-index: 1;
            background: rgba(0, 0, 0, 0.2);

            .scrollContainer {
              // border: 1px solid red;
              height: 100%;
              overflow-y: auto;
              display: flex;
              flex-wrap: wrap;
              align-content: flex-start;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 10px;

              span {
                min-width: max-content;
                min-height: max-content;
                padding: 5px 23px;
                background: rgba(217, 217, 217, 0.14);
                border-radius: 18px;
                border: 1px solid #fff;

                @media (max-width: 768.5px) {
                  font-size: 14px;
                  line-height: 14px;
                }
              }
            }
          }
        }

        .activeResult {
          max-height: 300px;

          .top {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }
}
