header {
  border-bottom: 1px solid #000;

  .contentWrapper {
    // border: 10px solid red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    max-width: 1440px;
    margin: 0 auto;
    transition: 0.25s ease all;

    @media (max-width: 1500px) {
      padding: 0 30px;
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      img {
        height: 35px;
      }

      span {
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;

        @media (max-width: 768.5px) {
          display: none;
        }
      }
    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      // border: 1px solid red;

      span {
        font-size: 16px;
        line-height: 16px;
        color: #ffffff80;

        @media (max-width: 768.5px) {
          display: none;
        }
      }
    }
  }
}
